function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}

window.loadReferences = function () {
    $.post('ajax.php',$('#reference-form').serialize(),function(json) {
        var allIds = [];
        $.each(json,function(index,jsonValue) {
            allIds.push(jsonValue.id);
            if($('[data-reference-id="'+jsonValue.id+'"]').length == 0) {
                var $htmlContainer = $(jsonValue.html);
                $htmlContainer.hide();
                $('[data-reference-list]').append($htmlContainer);
            }
        });

        //sortReferences();

        var $hideReferences = $('[data-reference-id]:visible'),
            $newReferences = $();
        $.each(allIds,function(index,id) {
            $hideReferences = $hideReferences.filter(':not([data-reference-id="'+id+'"])');
            $newReferences = $newReferences.add($('[data-reference-id="'+id+'"]:hidden'));
        });

        $hideReferences.animate({
            opacity: 'toggle',
            width: 'toggle',
            height: 'toggle'
        });
        $newReferences.animate({
            opacity: 'toggle',
            width: 'toggle',
            height: 'toggle'
        });

        //window.lazyImageLoad();
    });
}

$(document).ready(function(){

    $(".search").click(function(){
        $("#searchterm").focus();
    });

    $('.footer__scroll-top').click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    /*
     * Slick slider
     */
    $('.product-list').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 3,
        infinite: true,
        slidesToScroll: 3,
        swipe: false,
        // customPaging: function(slider, i) {
        //     var title = $(slider.$slides[i]).data('title');
        //     return '<a class="product-list__dot" href="javascript:void(0);"></a>';
        // },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipe: true,
                    dots: false,
                    arrows: false
                }
            }
        ]
    });

    // $('.themepic-slider').on('init', function(ev, el){
    //     $('video').each(function () {
    //         this.play();
    //     });
    // });
    $('.slider').slick({
        dots: true,
        arrows: false,
        autoplay: false,
        swipe: true,
        customPaging: function(slider, i) {
            var title = $(slider.$slides[i]).data('title');
            return '<a class="themepic-slider__dot" href="javascript:void(0);"></a>';
        }
        //swipe: false
    });

    $('.themepic-slider').slick({
        dots: true,
        arrows: false,
        //autoplay: true,
        swipe: true,
        customPaging: function(slider, i) {
            var title = $(slider.$slides[i]).data('title');
            return '<a class="themepic-slider__dot" href="javascript:void(0);"></a>';
        }
        //swipe: false
    });

    $('.themepic-slider-outer').slick({
        dots: true,
        arrows: false,
        //autoplay: true,
        swipe: true,
        customPaging: function(slider, i) {
            var title = $(slider.$slides[i]).data('title');
            return '<a class="themepic-slider__dot" href="javascript:void(0);"></a>';
        }
        //swipe: false
    });

    /*
     * Custom mobile nav
     */
    $(".icon-unfold").click(function(){

        $(this).toggleClass("icon-unfold--down");

        $(this).parents().closest('li').find('dropdown').addClass("open");

        var dropdown = $(this).next();

        dropdown.slideToggle();

    });


    /*
     * Fancybox
     */
    $(".fancybox").fancybox();


    /*Referenzfilter*/
    $('.reference-select').off('change').on('change', function () {
        var industry    = $('[name="reference_filter[industry]"]').find('option:selected').val();
        setCookie('ref_industry', industry, 1);
        var product     = $('[name="reference_filter[product]"]').find('option:selected').val();
        setCookie('ref_product', product, 1);
        var productgroup     = $('[name="reference_filter[productgroup]"]').find('option:selected').val();
        setCookie('ref_productgroup', productgroup, 1);

        if(industry != '' || product != '' || productgroup != '') {
            window.location.hash = 'i-' + industry + '_p-' + product + '_g-' + productgroup;
        } else {
            //window.location.hash = window.location.hash.replace(/i-(\d*)_p-(\d*)/,'');
            window.location.hash = 'i-_p-_g-';
        }
    });

    if($('.reference-select').length > 0 && window.location.hash == '') {
        var hasChanged = false;
        if(getCookie('ref_industry') != '') {
            $('[name="reference_filter[industry]"]').find('option[value="' + getCookie('ref_industry') + '"]').attr('selected', 'selected');
            hasChanged = true;
        }

        if(getCookie('ref_product') != '') {
            $('[name="reference_filter[product]"]').find('option[value="' + getCookie('ref_product') + '"]').attr('selected', 'selected');
            hasChanged = true;
        }

        if(getCookie('ref_productgroup') != '') {
            $('[name="reference_filter[productgroup]"]').find('option[value="' + getCookie('ref_productgroup') + '"]').attr('selected', 'selected');
            hasChanged = true;
        }

        if(hasChanged) {
            //$('.reference-select').change();
            window.loadReferences();
        }
    } else if ($('.reference-select').length > 0 && window.location.hash != '' && window.location.hash.match(/i-(\d*)_p-(\d*)_g-(\d*)/)) {
        var industryId  = window.location.hash.split('_')[0].split('-')[1];
        var productId   = window.location.hash.split('_')[1].split('-')[1];
        var productgroupId   = window.location.hash.split('_')[2].split('-')[1];

        $('[name="reference_filter[industry]"]').find('option[value="' + industryId + '"]').attr('selected', 'selected');
        $('[name="reference_filter[product]"]').find('option[value="' + productId + '"]').attr('selected', 'selected');
        $('[name="reference_filter[productgroup]"]').find('option[value="' + productgroupId + '"]').attr('selected', 'selected');

        //$('.reference-select').change();
        window.loadReferences();
    }

    $(window).scroll(function () {

        //console.log($(window).scrollTop());

        if ($(window).scrollTop() > 126) {
            $('.nav-outer').addClass('navbar-fixed');
        }

        if ($(window).scrollTop() < 127) {
            $('.nav-outer').removeClass('navbar-fixed');
        }

        if ($(window).scrollTop() > 160) {
            $('.logo-small').addClass('visible');
        }

        if ($(window).scrollTop() < 161) {
            $('.logo-small').removeClass('visible');
        }

    });

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    if ( $(".section--products").length) {
        $(window).on('DOMContentLoaded load resize scroll', function() {

            if (($('.section--products').isInViewport()) && (!$('.footer').isInViewport())) {
                setTimeout( "$('.rfq').addClass('rfq--visible');", 2000);
            }

        });
    }

});